import { createSlice } from "@reduxjs/toolkit";

// {
//     id:"",
//     image:"",
//     name:"nama",
//     type:"",
//     pts:"0",
//     qty:1,
//     note,

// }
const cartSlice = createSlice({
    name: "cart",
    initialState: {
        data: JSON.parse(localStorage.getItem("carts")) || [],
    },
    reducers: {
        addToCart: (state, action) => {
            const itemInCart = state.data.find(
                (item) => item.id === action.payload.id
            );
            if (itemInCart) {
                itemInCart.qty++;
            } else {
                state.data.push(action.payload);
            }
        },
        incCart: (state, action) => {
            const itemInCart = state.data.find(
                (item) => item.id === action.payload.id
            );
            if (itemInCart) {
                itemInCart.qty += action.payload.qty;
            }
        },
        decCart: (state, action) => {
            const itemInCart = state.data.find(
                (item) => item.id === action.payload.id
            );
            if (itemInCart) {
                itemInCart.qty -= action.payload.qty;
            }
        },
        inputNote: (state, action) => {
            const itemInCart = state.data.find(
                (item) => item.id === action.payload.id
            );
            if (itemInCart) {
                itemInCart.note = action.payload.note;
            }
        },
        removeCartItem: (state, action) => {
            // const remove = state.data.filter(
            //     (item) => item.id !== action.payload.id);
                state.data = state.data.filter((arrow) => arrow.id !== action.payload.id);
            // return state.data;
        },
    },
});

export const { addToCart, incCart, decCart, removeCartItem ,inputNote} =
    cartSlice.actions;
export default cartSlice.reducer;
