import React from "react";

import IconDel from "../assets/images/redemption/icon-delete.svg";
import IconEdit from "../assets/images/redemption/icon-edit.svg";
import IconPlus from "../assets/images/redemption/icon-plus.svg";
import IconMin from "../assets/images/redemption/icon-min.svg";

import MGopay from "../assets/images/redemption/merchandise-gopay.jpg";
import MClaren from "../assets/images/redemption/merchandise-mclaren.png";

function RedeemCardComponent(props) {
    return (
        <>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb32">
                <div className="redeem-card d-flex flex-wrap full">
                    <div className="redeem-card-image">
                        <img
                            alt="Vuse Merchandise"
                            className="inline-block"
                            src={MGopay}
                        />
                    </div>
                    <div className="redeem-card-spec d-flex">
                        <div className="redeem-card-type">
                            <p className="vuse-font c-grey fst-italic">
                                Gopay Topup
                            </p>
                        </div>
                        <div className="redeem-card-name">
                            <p className="vuse-font bold line-clamp-2">
                                Rp 75.000
                            </p>
                        </div>
                        <div className="redeem-card-point">
                            <p className="vuse-font bold text-uppercase">
                                150 Pts
                            </p>
                        </div>
                        <div className="redeem-card-cta d-flex">
                            <div className="cta">
                                <button className="vuse-btn btn-commerce">
                                    <img
                                        alt="Icon Vuse"
                                        className=""
                                        src={IconDel}
                                    />
                                </button>
                            </div>
                            <div className="cta">
                                <button className="vuse-btn btn-commerce">
                                    <img
                                        alt="Icon Vuse"
                                        className=""
                                        src={IconEdit}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="redeem-qty">
                        <div className="quantity-input">
                            <button className="vuse-btn btn-commerce">
                                <img
                                    alt="Icon Vuse"
                                    className=""
                                    src={IconMin}
                                />
                            </button>
                            <input
                                className="input-increment"
                                type="text"
                                value="1"
                                readonly
                            />
                            <button className="vuse-btn btn-commerce">
                                <img
                                    alt="Icon Vuse"
                                    className=""
                                    src={IconPlus}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RedeemCardComponent;
