import React, { useEffect, useNavigate } from "react";
import { Navigate } from "react-router-dom";
function Logout() {
    useEffect(() => {
        <Navigate to="/notfound" replace />;
    },[]);

    return <>lorem</>;
}

export default Logout;
