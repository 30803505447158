import {React} from "react";

function HeaderCatalog({data}) {
    const {tier_background,tier_name,tier_icon,tier_card,tier_card_template,points_low,points_high} = data;
    return (
        <>
            <section
                className="section-member-banner"
                style={{
                    background:tier_background
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                            <div className="member-banner-container d-flex">
                                <div className="member-banner-point d-flex">
                                    <div className="banner-point-image d-flex align-items-center">
                                        <img
                                            alt="Vuse Level"
                                            className="inline-block"
                                            src={tier_icon}
                                        />
                                        <div className="inline-block">
                                            <p className="vuse-font">Levelmu</p>
                                            <h4 className="vuse-font x-bold text-uppercase">
                                                {tier_name}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="banner-point-total text-end">
                                        <p className="vuse-font">
                                            Nilai Pembelian
                                        </p>
                                        <h4 className="vuse-font x-bold text-uppercase">
                                           {points_high}
                                        </h4>
                                    </div>
                                </div>
                                <div className="member-banner-card">
                                    <img alt="Vuse Level" src={tier_card} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeaderCatalog;
