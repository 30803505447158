import React from "react";
import { Link } from "react-router-dom";

import IconCheck from "../assets/images/icon-check.svg";
import ArrowLeft from "../assets/images/redemption/arrow-left.svg";

import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";

function Checkout(props) {
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <section className="section-thankyou">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="ty-wrapper">
                                    <div className="ty-wrapper-image text-center">
                                        <img alt="Vuse Icon" src={IconCheck} />
                                        <h3 className="vuse-font x-bold text-uppercase mb16">
                                            Terima kasih atas penukaran poin
                                        </h3>
                                    </div>
                                    <div className="ty-wrapper-desc text-center">
                                        <p className="vuse-font mb16">
                                            Nomor penukaran poin anda adalah
                                            xxxx
                                        </p>
                                        <p className="vuse-font">
                                            Kami juga telah mengirimkan detail
                                            order Anda
                                            <br />
                                            ke{" "}
                                            <u className="x-bold">
                                                xxx@email.com
                                            </u>
                                        </p>
                                        <div className="cta text-center mt40 mb40">
                                            <Link
                                                to="/account"
                                                className="vuse-btn btn-contained text-uppercase full"
                                                > Kembali ke akun
                                            </Link>
                                        </div>
                                        {/* <div className="cta text-center">
                                            <Link
                                                to="/"
                                                className="vuse-font bold text-uppercase"
                                            >
                                                <img
                                                    alt="Vuse Icon"
                                                    src={ArrowLeft}
                                                />
                                                Kembali ke home
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default Checkout;
