import React from "react";

class ToggleKnob extends React.Component {
    render() {
        return (
            <span
                className={`toggleKnob 
                      ${this.props.isOn ? "isActive" : ""}`}
            ></span>
        );
    }
}

class ToggleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOn: false,
        };
    }

    handleClick() {
        this.setState((prevState) => ({
            isOn: !prevState.isOn,
        }));
        console.log(this.state.isOn);
    }

    render(props) {
        return (
            <div className="toggle-wrapper">
                <button
                    className={`toggleContainer ${
                        this.state.isOn ? "isActive" : ""
                    } relative`}
                    onClick={() => this.handleClick()}
                >
                    <ToggleKnob isOn={this.state.isOn} />
                    <div className="toggle-times">
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>
        );
    }
}

export default ToggleComponent;
