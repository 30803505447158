import React from "react";

import IconDel from "../assets/images/redemption/icon-delete.svg";
import IconEdit from "../assets/images/redemption/icon-edit.svg";
import IconPlus from "../assets/images/redemption/icon-plus.svg";
import IconMin from "../assets/images/redemption/icon-min.svg";

import MGopay from "../assets/images/redemption/merchandise-gopay.jpg";
import MClaren from "../assets/images/redemption/merchandise-mclaren.png";

import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";
import NavBackTo from "../components/NavBackTo";
import { Link } from "react-router-dom";
function Checkout(props) {
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <NavBackTo backTo="/redemption" title="Check Out" />
                <section className="section-checkout">
                    <div className="container">
                        <div className="row row-member">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="subject-tittle">
                                    <h3 className="vuse-font black bold text-uppercase">
                                        KATALOG REWARD
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb32">
                                <div className="redeem-card d-flex flex-wrap full">
                                    <div className="redeem-card-image">
                                        <img
                                            alt="Vuse Merchandise"
                                            className="inline-block"
                                            src={MClaren}
                                        />
                                    </div>
                                    <div className="redeem-card-spec d-flex">
                                        <div className="redeem-card-type">
                                            <p className="vuse-font c-grey fst-italic">
                                                Merchandise
                                            </p>
                                        </div>
                                        <div className="redeem-card-name">
                                            <p className="vuse-font bold line-clamp-2">
                                                Merchandise McLaren
                                            </p>
                                        </div>
                                        <div className="redeem-card-point">
                                            <p className="vuse-font bold text-uppercase">
                                                1.000 Pts
                                            </p>
                                        </div>
                                        <div className="redeem-card-cta d-flex">
                                            <div className="cta">
                                                <button className="vuse-btn btn-commerce">
                                                    <img
                                                        alt="Icon Vuse"
                                                        className=""
                                                        src={IconDel}
                                                    />
                                                </button>
                                            </div>
                                            <div className="cta">
                                                <button className="vuse-btn btn-commerce">
                                                    <img
                                                        alt="Icon Vuse"
                                                        className=""
                                                        src={IconEdit}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="redeem-qty">
                                        <div className="quantity-input">
                                            <button className="vuse-btn btn-commerce">
                                                <img
                                                    alt="Icon Vuse"
                                                    className=""
                                                    src={IconMin}
                                                />
                                            </button>
                                            <input
                                                className="input-increment"
                                                type="text"
                                                value="1"
                                                readonly
                                            />
                                            <button className="vuse-btn btn-commerce">
                                                <img
                                                    alt="Icon Vuse"
                                                    className=""
                                                    src={IconPlus}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb32">
                                <div className="redeem-card d-flex flex-wrap full">
                                    <div className="redeem-card-image">
                                        <img
                                            alt="Vuse Merchandise"
                                            className="inline-block"
                                            src={MGopay}
                                        />
                                    </div>
                                    <div className="redeem-card-spec d-flex">
                                        <div className="redeem-card-type">
                                            <p className="vuse-font c-grey fst-italic">
                                                Gopay Topup
                                            </p>
                                        </div>
                                        <div className="redeem-card-name">
                                            <p className="vuse-font bold line-clamp-2">
                                                Rp 10.000
                                            </p>
                                        </div>
                                        <div className="redeem-card-point">
                                            <p className="vuse-font bold text-uppercase">
                                                20 Pts
                                            </p>
                                        </div>
                                        <div className="redeem-card-cta d-flex">
                                            <div className="cta">
                                                <button className="vuse-btn btn-commerce">
                                                    <img
                                                        alt="Icon Vuse"
                                                        className=""
                                                        src={IconDel}
                                                    />
                                                </button>
                                            </div>
                                            <div className="cta">
                                                <button className="vuse-btn btn-commerce">
                                                    <img
                                                        alt="Icon Vuse"
                                                        className=""
                                                        src={IconEdit}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="redeem-qty">
                                        <div className="quantity-input">
                                            <button className="vuse-btn btn-commerce">
                                                <img
                                                    alt="Icon Vuse"
                                                    className=""
                                                    src={IconMin}
                                                />
                                            </button>
                                            <input
                                                className="input-increment"
                                                type="text"
                                                value="1"
                                                readonly
                                            />
                                            <button className="vuse-btn btn-commerce">
                                                <img
                                                    alt="Icon Vuse"
                                                    className=""
                                                    src={IconPlus}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb32">
                                <div className="redeem-card d-flex flex-wrap full">
                                    <div className="redeem-card-image">
                                        <img
                                            alt="Vuse Merchandise"
                                            className="inline-block"
                                            src={MGopay}
                                        />
                                    </div>
                                    <div className="redeem-card-spec d-flex">
                                        <div className="redeem-card-type">
                                            <p className="vuse-font c-grey fst-italic">
                                                Gopay Topup
                                            </p>
                                        </div>
                                        <div className="redeem-card-name">
                                            <p className="vuse-font bold line-clamp-2">
                                                Rp 75.000
                                            </p>
                                        </div>
                                        <div className="redeem-card-point">
                                            <p className="vuse-font bold text-uppercase">
                                                150 Pts
                                            </p>
                                        </div>
                                        <div className="redeem-card-cta d-flex">
                                            <div className="cta">
                                                <button className="vuse-btn btn-commerce">
                                                    <img
                                                        alt="Icon Vuse"
                                                        className=""
                                                        src={IconDel}
                                                    />
                                                </button>
                                            </div>
                                            <div className="cta">
                                                <button className="vuse-btn btn-commerce">
                                                    <img
                                                        alt="Icon Vuse"
                                                        className=""
                                                        src={IconEdit}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="redeem-qty">
                                        <div className="quantity-input">
                                            <button className="vuse-btn btn-commerce">
                                                <img
                                                    alt="Icon Vuse"
                                                    className=""
                                                    src={IconMin}
                                                />
                                            </button>
                                            <input
                                                className="input-increment"
                                                type="text"
                                                value="1"
                                                readonly
                                            />
                                            <button className="vuse-btn btn-commerce">
                                                <img
                                                    alt="Icon Vuse"
                                                    className=""
                                                    src={IconPlus}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-checkout">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="checkout-option">
                                    <div className="checkout-left">
                                        <p className="vuse-font bold mb8">
                                            Masukan alamat pengiriman
                                        </p>
                                        <p className="vuse-font c-grey fst-italic">
                                            Masukan alamat pengiriman
                                        </p>
                                    </div>
                                    <div className="checkout-right">
                                        <button className="vuse-btn btn-checkout">
                                            Ubah
                                        </button>
                                    </div>
                                </div>
                                <div className="checkout-option">
                                    <div className="checkout-left">
                                        <p className="vuse-font bold mb8">
                                            Metode pengiriman
                                        </p>
                                        <p className="vuse-font c-grey fst-italic">
                                            Pilih metode pengiriman
                                        </p>
                                    </div>
                                    <div className="checkout-right">
                                        <button
                                            className="vuse-btn btn-checkout btn-disabled"
                                            disabled
                                        >
                                            Pilih
                                        </button>
                                    </div>
                                </div>
                                <div className="checkout-option">
                                    <div className="checkout-left">
                                        <p className="vuse-font bold mb8">
                                            Daftar pesanan
                                        </p>
                                        <p className="vuse-font c-grey fst-italic">
                                            Total penukaran
                                        </p>
                                    </div>
                                    <div className="checkout-right">
                                        <h6 className="vuse-font text-uppercase">
                                            XXX POIN
                                        </h6>
                                    </div>
                                </div>
                                <div className="checkout-option">
                                    <div className="checkout-left">
                                        <p className="vuse-font bold mb8">
                                            Jumlah Total
                                        </p>
                                    </div>
                                    <div className="checkout-right">
                                        <h6 className="vuse-font c-green text-uppercase">
                                            XXX POIN
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt48 mb16">
                            <div className="col-12 col-sm-2 offset-sm-5 col-md-2 offset-md-5 col-lg-2 offset-lg-5 text-center">
                                <Link
                                    to={"/thankyou"}
                                    className="vuse-btn btn-contained text-uppercase full"
                                    >
                                    Confirm
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default Checkout;
