import React from "react";
import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";
import CopyComponent from "../components/CopyComponent";

import IconWA from "../assets/images/referral/icon-wa.png";
import IconIG from "../assets/images/referral/icon-ig.png";
import IconMessenger from "../assets/images/referral/icon-messenger.png";
import IconEmail from "../assets/images/referral/icon-email.png";

import ReferralBanner from "../assets/images/referral/ref-banner.jpg";

function ReferralPage() {
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <section className="section-referral ov-hid mb64">
                    <div className="container-fuid no-pad">
                        <div
                            className="ref-banner"
                            style={{
                                backgroundImage: "url(" + ReferralBanner + ")",
                            }}
                        ></div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="ref-container">
                                    <div className="ref-word mb32">
                                        <h4 className="vuse-font bold text-uppercase mb8">
                                            ajak teman dengan kode referral
                                        </h4>
                                        <p className="vuse-font">
                                            Dapatkan Gopay IDR 20K untuk setiap
                                            teman yang berhasil kamu ajak!
                                        </p>
                                    </div>
                                    <div className="ref-word mb32">
                                        <h4 className="vuse-font bold text-uppercase mb8">
                                            CARANYA:
                                        </h4>
                                        <ul className="p-0 m-0">
                                            <li className="vuse-font mb4">
                                                <span className="bold">
                                                    Step 1
                                                </span>
                                                : Ketuk untuk menyalin link
                                                referral
                                            </li>
                                            <li className="vuse-font mb4">
                                                <span className="bold">
                                                    Step 2
                                                </span>
                                                : Bagikan ke temanmu
                                            </li>
                                            <li className="vuse-font mb4">
                                                <span className="bold">
                                                    Step 3
                                                </span>
                                                : Dapatkan reward setelah
                                                temanmu mendaftar
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ref-divider"></div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="ref-word text-center mb48">
                                    <h5 className="vuse-font">
                                        Kode Referalmu
                                    </h5>
                                    <CopyComponent />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="ref-word text-center mb32">
                                    <h5 className="vuse-font">
                                        Bagikan ke Temanmu melalui
                                    </h5>
                                </div>
                                <div className="ref-share">
                                    <div className="ref-share-object">
                                        <button className="vuse-btn btn-white p-0">
                                            <img alt="Icon Vuse" src={IconWA} />
                                        </button>
                                    </div>
                                    <div className="ref-share-object">
                                        <button className="vuse-btn btn-white p-0">
                                            <img alt="Icon Vuse" src={IconIG} />
                                        </button>
                                    </div>
                                    <div className="ref-share-object">
                                        <button className="vuse-btn btn-white p-0">
                                            <img alt="Icon Vuse" src={IconMessenger} />
                                        </button>
                                    </div>
                                    <div className="ref-share-object">
                                        <button className="vuse-btn btn-white p-0">
                                            <img alt="Icon Vuse" src={IconEmail} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default ReferralPage;
