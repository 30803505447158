import React from "react";

const ProgressBarComponent = (props) => {
    const { bgcolor, completed } = props;

    const fillerStyles = {
        width: `${completed}%`,
        backgroundColor: bgcolor,
    };

    const labelStyles = {
        padding: 5,
        color: "white",
        fontWeight: "bold",
        fontSize: 12,
    };

    return (
        <>
            <div className="account-level-progress">
                <div className="level-progress-wrapper">
                    <div className="level-progres-bar" style={fillerStyles}>
                        {/* <span style={labelStyles}>{`${completed}%`}</span> */}
                    </div>
                </div>
                <div className="level-progress-reach">
                    <p className="vuse-small">
                        Spend 100.000 and wait until
                        <b> 31 December 2022</b> to reach
                        <b> Platinum</b>
                    </p>
                </div>
            </div>
        </>
    );
};

export default ProgressBarComponent;
