import axios from "axios";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IsLoggedIn } from "../redux/slices/CommonSlice";
import { DataUser } from "../redux/slices/UserSlice";

export const RegisterStepOne = async (data) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/register/step-one`,
        data,
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    return response;
};
export const RegisterStepTwo = async (data) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/register/step-two`,
        data,
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    return response;
};
export const RegisterStepThree = async (data) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/register/step-three`,
        data,
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    return response;
};

export const Login = async (data) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        data,
        {
            withCredentials: true,
        }
    );
    return response;
};
export const LoginWithGoogle = async (data) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        data,
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    return response;
};

// =========== INTERCEPTORS ======
const axiosJWT = axios.create();
axiosJWT.interceptors.request.use(
    async (config) => {
        const currentdate = new Date();
        const expiredToken = localStorage.getItem("_exp");
        console.log("exp :"+ expiredToken*1000);
        console.log("current :"+ currentdate.getTime());
        if (expiredToken * 1000 < currentdate.getTime()) {
            //     // panggil refresh token endpoint
            await axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}/auth/refresh-token`,
                    {},
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    const data = response.data.data;
                    const token = data.access_token;
                    const decoded = jwt_decode(token);
                    localStorage.setItem("access_token", token);
                    localStorage.setItem("_exp", decoded.exp);
                });

            // localStorage.removeItem("_user");
            // localStorage.removeItem("access_token");
            // localStorage.removeItem("_exp");
            // localStorage.removeItem("logged_in");
            // dispatch(DataUser(null));
            // dispatch(IsLoggedIn(false));
            // console.log('refresh token dlu');
        }
        // console.log('call interceptor');
        return config;
    },
    (error) => {
        // const dispatch = useDispatch();
        localStorage.removeItem("_user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("_exp");
        localStorage.removeItem("logged_in");
        window.location.href = "/";
        // dispatch(DataUser(null));
        // dispatch(IsLoggedIn(false));
        return Promise.reject(error);
    }
);
// =========== LOGOUT ======
export const Logout = async () => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/logout`,
        {},
        {
            withCredentials: true,
        }
    );
    return response;
};
// =========== TOKEN ======
export const RefreshToken = async (data) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/refresh-token`,
        data,
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    return response;
};
export const MatchToken = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/auth/match-token`,
        {
            withCredentials: true,
        }
    );
    return response;
};

// ========  TIERING =====
export const GetAllTier = async () => {
    const response = await axiosJWT.get(
        `${process.env.REACT_APP_BASE_URL}/tiers`,
        {
            withCredentials: true,
        }
    );
    return response;
};
export const GetTier = async (name) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/tiers/${name}`,
        {
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + token,
            },
            withCredentials: true,
        }
    );
    return response;
};
export const GetMyTier = async (token) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/tiers/my-card`,
        {
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Bearer " + token,
            },
            withCredentials: true,
        }
    );
    return response;
};
// ========  VOUCHER =====
export const Voucher = async (id, token) => {
    const response = await axiosJWT.get(
        `${process.env.REACT_APP_BASE_URL}/vouchers/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        }
    );
    return response;
};
export const GetVouchers = async (params, token) => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/vouchers`,
        {
            params: params,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            withCredentials: true,
        }
    );
    return response;
};
export const CollectPoint = async (data, token) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/collect`,
        data,
        {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }
    );
    return response;
};

export const Warranty = async (data, token) => {
    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/vouchers/warranty`,
        data,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                // Authorization: "Bearer " + token,
            },
            withCredentials: true,
        }
    );
    return response;
};
// export default Api;
