import { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Login } from "../Service/Api";
import { IsLoggedIn } from "../redux/slices/CommonSlice";
import { useDispatch,useSelector } from "react-redux";
const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [parseData, setParseData] = useState({
        email: "",
        password: "",
    });
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setParseData({ ...parseData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Login(parseData).then((response) => {
                const data = response.data.data;
                const token = data.tokens;
                localStorage.setItem("access_token", token.access_token);
                localStorage.setItem("refresh_token", token.refresh_token);
                const decoded = jwt_decode(data.tokens.access_token);
                const decoded2 = jwt_decode(data.tokens.refresh_token);
                dispatch(IsLoggedIn(true));
            });
            navigate('/account')
        } catch (error) {
            console.log(error);
        }
    };
    const validateInput = () => {
        const password = parseData.password;
        const passwordLength = password.length;
        if (passwordLength < 1) {
            console.log("password harus diisi");
            return false;
        }
    };

    return (
        <div>
            <section className="section-dashboard mt-3 p-2">
                <form action="" onSubmit={handleSubmit}>
                    <div class="form-group mb-3">
                        <input
                            type="text"
                            className="text-dark form-control border medium"
                            placeholder="Email"
                            aria-label="Email"
                            name="email"
                            onChange={handleChangeInput}
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    <div class="form-group mb-3">
                        <input
                            type="text"
                            className="text-dark form-control border medium"
                            placeholder="Password"
                            aria-label="Password"
                            name="password"
                            onChange={handleChangeInput}
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary ">
                        Login
                    </button>
                </form>
            </section>
        </div>
    );
};

// function Loading() {
//     return (
//         <>
//             <SkeletonTheme>
//                 <div className="card mb-2">
//                     <div className="card-body">
//                         <Skeleton height={300} />
//                     </div>
//                 </div>
//             </SkeletonTheme>
//         </>
//     );
// }
export default LoginPage;
