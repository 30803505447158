import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";
import FormSendOTPComponent from "../components/FormSendOTPComponent";
import ToggleComponent from "../components/ToggleComponent";
import { RegisterStepThree } from "../Service/Api";
import { useDispatch, useSelector } from "react-redux";
import { OpenFormRegisterAfterSendOTP } from "../redux/slices/CommonSlice";
import ReCAPTCHA from "react-google-recaptcha";
import ArrowRight from "../assets/images/register/arrow-right.svg";

const RegisterForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const common_selector = useSelector((state) => state.common);
    const flagOpenForm = common_selector.open_form_register_after_send_otp; // jika flag false, maka hanya form input OTP yang terbuka
    const initilizeField = {
        name: "",
        email: "",
        birthdate: "",
        address: "",
        phone_number: "",
        password: "",
        greeting: "",
    };
    const [pwdIsShow, setPwdIsShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [uppercasePassword, setUppercasePassword] = useState(false);
    const [lowercasePassword, setLowercasePassword] = useState(false);
    const [digitsPassword, setDigitsPassword] = useState(false);
    const [specialCharPassword, setSpecialCharPassword] = useState(false);
    const [minLengthPassword, setMinLengthPassword] = useState(false);
    // ERROR MESSAGE
    const [errGreeting, setErrGreeting] = useState("");
    const [errName, setErrName] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [errBirthDay, setErrBirthDay] = useState("");
    const [errAddress, setErrAddress] = useState("");
    const [errPhoneNumber, setErrPhoneNumber] = useState("");
    const [errPassword, setErrPassword] = useState("");

    const [parseData, setParseData] = useState(initilizeField); // collect all state
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setParseData({ ...parseData, [name]: value });
    };
    const validateInputPassword = (password) => {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const VuppercasePassword = uppercaseRegExp.test(password);
        const VlowercasePassword = lowercaseRegExp.test(password);
        const VdigitsPassword = digitsRegExp.test(password);
        const VspecialCharPassword = specialCharRegExp.test(password);
        const VminLengthPassword = minLengthRegExp.test(password);
        let output = false;
        if (VuppercasePassword) {
            setUppercasePassword(true);
        } else {
            output = true;
            setUppercasePassword(false);
        }
        if (VlowercasePassword) {
            setLowercasePassword(true);
        } else {
            output = true;
            setLowercasePassword(false);
        }
        if (VdigitsPassword) {
            setDigitsPassword(true);
        } else {
            output = true;
            setDigitsPassword(false);
        }
        if (VspecialCharPassword) {
            setSpecialCharPassword(true);
        } else {
            output = true;
            setSpecialCharPassword(false);
        }
        if (VminLengthPassword) {
            setMinLengthPassword(true);
        } else {
            output = true;
            setMinLengthPassword(false);
        }
        return output;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        return false;
        if (!isVerify) {
            return false;
        }
        if (loading) {
            return false;
        }
        validateInputPassword(parseData.password);

        setLoading(true);
        try {
            await RegisterStepThree(parseData).then((response) => {
                setLoading(false);
                // dispatch(OpenFormRegisterAfterSendOTP)
                localStorage.removeItem("open_form_register_after_send_otp");
                localStorage.removeItem("input_phone_number");
                dispatch(OpenFormRegisterAfterSendOTP(false));
                navigate("/");
            });
        } catch (error) {
            setLoading(false);
            const errors = error.response.data.error;
            if (typeof errors == "string") {
                alert(errors);
            } else {
                for (var i in errors) {
                    if (i == "greeting") {
                        setErrGreeting(errors[i]);
                    }
                    if (i == "name") {
                        setErrName(errors[i]);
                    }
                    if (i == "email") {
                        setErrEmail(errors[i]);
                    }
                    if (i == "birthday") {
                        setErrBirthDay(errors[i]);
                    }
                    if (i == "address") {
                        setErrAddress(errors[i]);
                    }
                    if (i == "phone_number") {
                        setErrPhoneNumber(errors[i]);
                    }
                    if (i == "password") {
                        setErrPassword(errors[i]);
                    }
                }
            }
        }
    };

    useEffect(() => {
        const getPhoneNumber = common_selector.input_phone_number;
        setParseData({ ...parseData, phone_number: getPhoneNumber });
    }, [flagOpenForm]);
    return (
        <BodyContainer>
            <NavbarPrimaryComponent />
            <>
                <section className="section-register-form">
                    <div className="register-form-container">
                        <h2 className="vuse-font text-uppercase x-bold text-center mb32">
                            Buat Akun
                        </h2>
                        {/* {flagOpenForm ? ( */}
                        <FormSendOTPComponent />
                            <form action="" onSubmit={handleSubmit}>
                                <div className="container">
                                    <div className="register-divider mt48 mb48"></div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="custom-choose">
                                                <p className="vuse-font bold text-uppercase text-center mb8">
                                                    Your Details
                                                </p>
                                                <div className="radio-wrapper">
                                                    <input
                                                        type="radio"
                                                        className="radio-custom"
                                                        id="opt-1"
                                                        name="greeting"
                                                        value="mr"
                                                        onChange={
                                                            handleChangeInput
                                                        }
                                                        aria-describedby="basic-addon1"
                                                    />{" "}
                                                    <label
                                                        htmlFor="opt-1"
                                                        className="radio-custom"
                                                        id="opt-1"
                                                    >
                                                        Mr
                                                    </label>
                                                </div>
                                                <div className="radio-wrapper">
                                                    <input
                                                        type="radio"
                                                        className="radio-custom"
                                                        id="opt-2"
                                                        name="greeting"
                                                        value="mrs"
                                                        onChange={
                                                            handleChangeInput
                                                        }
                                                        aria-describedby="basic-addon1"
                                                    />{" "}
                                                    <label
                                                        htmlFor="opt-2"
                                                        className="radio-custom"
                                                        id="opt-2"
                                                    >
                                                        Mrs
                                                    </label>
                                                </div>
                                                <div className="radio-wrapper">
                                                    <input
                                                        type="radio"
                                                        className="radio-custom"
                                                        id="opt-3"
                                                        name="greeting"
                                                        value="miss"
                                                        onChange={
                                                            handleChangeInput
                                                        }
                                                        aria-describedby="basic-addon1"
                                                    />
                                                    <label
                                                        htmlFor="opt-3"
                                                        className="radio-custom"
                                                        id="opt-3"
                                                    >
                                                        Miss
                                                    </label>
                                                </div>
                                                <div className="radio-wrapper">
                                                    <input
                                                        type="radio"
                                                        className="radio-custom"
                                                        id="opt-4"
                                                        name="greeting"
                                                        value="ms"
                                                        onChange={
                                                            handleChangeInput
                                                        }
                                                        aria-describedby="basic-addon1"
                                                    />{" "}
                                                    <label
                                                        htmlFor="opt-4"
                                                        className="radio-custom"
                                                        id="opt-4"
                                                    >
                                                        Ms
                                                    </label>
                                                </div>
                                                <p className="invalid">
                                                    {errGreeting ?? ""}
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className="control-label bold text-uppercase"
                                                    htmlFor="text"
                                                >
                                                    NAMA
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nama"
                                                    aria-label="Nama"
                                                    name="name"
                                                    value={parseData.name}
                                                    onChange={handleChangeInput}
                                                    aria-describedby="basic-addon1"
                                                />
                                                <p className="invalid">
                                                    {errName ?? ""}
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className="control-label bold text-uppercase"
                                                    htmlFor="email"
                                                >
                                                    Alamat Email
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    aria-label="Email"
                                                    name="email"
                                                    value={parseData.email}
                                                    onChange={handleChangeInput}
                                                    aria-describedby="basic-addon1"
                                                />
                                                <p className="invalid">
                                                    {errEmail ?? ""}
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className="control-label bold text-uppercase"
                                                    htmlFor="date"
                                                >
                                                    Tanggal Lahir
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="Tanggal lahir"
                                                    aria-label="Tanggal lahir"
                                                    name="birthdate"
                                                    onChange={handleChangeInput}
                                                    aria-describedby="basic-addon1"
                                                />
                                                <p className="invalid">
                                                    {errBirthDay ?? ""}
                                                </p>
                                                <p className="vuse-font c-grey mt8">
                                                    Ini untuk membantu
                                                    memverifikasi usia Anda
                                                </p>
                                            </div>
                                            <div className="register-divider mt48 mb48"></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <h2 className="vuse-font x-bold text-uppercase mb32">
                                                ALAMAT KAMU
                                            </h2>
                                            <div className="form-group">
                                                <label
                                                    className="control-label bold text-uppercase"
                                                    htmlFor="text"
                                                >
                                                    PENCARIAN SINGKAT
                                                </label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Ketik alamatmu di sini"
                                                    aria-label="Alamat"
                                                    name="address"
                                                    onChange={handleChangeInput}
                                                    aria-describedby="basic-addon1"
                                                />
                                                <p className="invalid">
                                                    {errAddress ?? ""}
                                                </p>
                                                <div className="forget-pass-link mt8">
                                                    <label
                                                        htmlFor=""
                                                        className="control-label"
                                                    >
                                                        <Link
                                                            to=""
                                                            className="c-black bold"
                                                        >
                                                            TULIS MANUAL?{" "}
                                                            <span className="">
                                                                <img
                                                                    alt=""
                                                                    src={
                                                                        ArrowRight
                                                                    }
                                                                ></img>
                                                            </span>
                                                        </Link>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    className="control-label bold text-uppercase"
                                                    htmlFor="email"
                                                >
                                                    NOMOR PONSEL
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    aria-label="Phone"
                                                    name="phone_number"
                                                    value={
                                                        parseData.phone_number
                                                    }
                                                    onChange={handleChangeInput}
                                                    aria-describedby="basic-addon1"
                                                />
                                                <p className="invalid">
                                                    {errPhoneNumber ?? ""}
                                                </p>
                                            </div>
                                            <div className="form-group d-flex align-items-center">
                                                <ToggleComponent />
                                                <span className="vuse-font c-grey ms-3">
                                                    Saya tidak memiliki nomor
                                                    ponsel
                                                </span>
                                            </div>
                                            <h2 className="vuse-font x-bold text-uppercase mb32">
                                                Detail pendaftaran
                                            </h2>
                                            <div className="form-group">
                                                <label
                                                    className="control-label bold text-uppercase"
                                                    htmlFor="password"
                                                >
                                                    BUAT KATA SANDI
                                                </label>
                                                <input
                                                    type={
                                                        pwdIsShow
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    className="text-dark form-control border medium"
                                                    placeholder="Password"
                                                    aria-label="Password"
                                                    name="password"
                                                    value={parseData.password}
                                                    onChange={(e) => {
                                                        validateInputPassword(
                                                            e.target.value
                                                        );
                                                        handleChangeInput(e);
                                                    }}
                                                    aria-describedby="basic-addon1"
                                                />

                                                {errPassword ?? ""}
                                            </div>
                                            <div className="form-group">
                                                <ul className="ul-pass">
                                                    <p className="vuse-font">
                                                        Password harus
                                                        mengandung 3 karakter
                                                        berikut:
                                                    </p>
                                                    <li
                                                        className={
                                                            lowercasePassword
                                                                ? "valid"
                                                                : "invalid"
                                                        }
                                                    >
                                                        Satu huruf kecil
                                                    </li>
                                                    <li
                                                        className={
                                                            uppercasePassword
                                                                ? "valid"
                                                                : "invalid"
                                                        }
                                                    >
                                                        Satu huruf kapital
                                                    </li>
                                                    <li
                                                        className={
                                                            digitsPassword
                                                                ? "valid"
                                                                : "invalid"
                                                        }
                                                    >
                                                        Satu angka
                                                    </li>
                                                    <li
                                                        className={
                                                            specialCharPassword
                                                                ? "valid"
                                                                : "invalid"
                                                        }
                                                    >
                                                        Satu karakter special,
                                                        cth: #?!@$%^&*-
                                                    </li>
                                                    <div className="register-divider mt8 mb16"></div>
                                                    <li
                                                        className={
                                                            minLengthPassword
                                                                ? "valid"
                                                                : "invalid"
                                                        }
                                                    >
                                                        Minimal 8 karakter
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="register-terms">
                                                <p className="vuse-font">
                                                    Saya adalah pengguna produk
                                                    nikotin berusia 18 tahun
                                                    keatas, dan saya setuju
                                                    dengan Kebijakan Privasi
                                                    yang berlaku di website ini,
                                                    serta saya bersedia
                                                    dihubungi dan setuju untuk
                                                    mendapatkan berbagai
                                                    komunikasi dan materi
                                                    promosi melalui media
                                                    komunikasi apapun.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt32">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="register-captcha">
                                                <ReCAPTCHA
                                                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                    onChange={(e) => {
                                                        setIsVerify(true);
                                                    }}
                                                />
                                            </div>
                                            <div className="cta mt32">
                                                <button
                                                    type="submit"
                                                    className="vuse-btn btn-contained bold text-uppercase full"
                                                >
                                                    {loading
                                                        ? "Loading ..."
                                                        : "Kirim"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        {/* ) : (
                            <FormSendOTPComponent />
                        )} */}
                    </div>
                </section>
            </>
        </BodyContainer>
    );
};

// function Loading() {
//     return (
//         <>
//             <SkeletonTheme>
//                 <div className="card mb-2">
//                     <div className="card-body">
//                         <Skeleton height={300} />
//                     </div>
//                 </div>
//             </SkeletonTheme>
//         </>
//     );
// }
export default RegisterForm;
