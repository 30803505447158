import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NotFound from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";
import RouteGuard from "./Auth/RouteGuard";
import RegisterForm from "./pages/RegisterForm";
import Logout from "./Auth/Logout";
import RegistrationPage from "./pages/RegistrationPage";
import AccountPage from "./pages/AccountPage";
import NotificationPage from "./pages/NotificationPage";
import MemberPage from "./pages/MemberPage";
import RedemptionPage from "./pages/RedemptionPage";
import CheckoutPage from "./pages/CheckoutPage";
import ThankyouPage from "./pages/ThankyouPage";
import WarrantyPage from "./pages/WarrantyPage";
import ReferralPage from "./pages/ReferralPage";
import PointPage from "./pages/PointPage";

<style>@import url('https://fonts.cdnfonts.com/css/gotham-6');</style>;

function App() {
    return (
        <div className="main-panel">
            <Router>
                <Routes>
                    {/* <Route path="/" element={<HomePage />}></Route> */}
                    <Route
                        path="/"
                        element={
                            // <RouteGuard mustAuthenticated={false}>
                                <RegistrationPage />
                            // </RouteGuard>
                        }></Route>
                    <Route
                        path="/registration-form"
                        element={
                            // <RouteGuard mustAuthenticated={false}>
                                <RegisterForm />
                            // </RouteGuard>
                        }></Route>
                    <Route
                        path="/account"
                        element={
                            // <RouteGuard mustAuthenticated={true}>
                                <AccountPage />
                            // </RouteGuard>
                        }></Route>
                    <Route
                        path="/notification"
                        element={
                            // <RouteGuard mustAuthenticated={false}>
                                <NotificationPage />
                            // </RouteGuard>
                        }></Route>
                    <Route
                        path="/member/:level"
                        element={<MemberPage />}></Route>
                    <Route
                        path="/redemption"
                        element={<RedemptionPage />}></Route>
                    <Route path="/checkout" element={<CheckoutPage />}></Route>
                    <Route path="/point" element={<PointPage />}></Route>
                    <Route path="/referral" element={<ReferralPage />}></Route>
                    <Route path="/warranty" element={<WarrantyPage />}></Route>
                    <Route path="/thankyou" element={<ThankyouPage />}></Route>

                    {/* <Route
                        path="/"
                        element={
                            <RouteGuard mustAuthenticated={true}>
                                <Home />
                            </RouteGuard>
                        }
                    ></Route> */}
                    <Route
                        path="/register"
                        element={
                            // <RouteGuard mustAuthenticated={false}>
                            <RegisterForm />
                            // </RouteGuard>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <RouteGuard mustAuthenticated={false}>
                                <LoginPage />
                            </RouteGuard>
                        }></Route>
                    {/* <Route path="/register" element={<RegisterForm />}></Route> */}
                    {/* <Route path="/setting" element={<SettingPage />}></Route> */}
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
