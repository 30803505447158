import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { MatchToken } from "../Service/Api";
const RouterGuard = ({ mustAuthenticated, children }) => {
    const isLoggedIn = useSelector((state) => state.common.isLoggedIn);
    // const checkToken = async () =>{
    //     try {
    //            MatchToken().then((response) =>{
    //             console.log(response);
    //         });
    //     } catch (error) {
    //         // jika token exipre maka coba call refresh token
    //     }
    // }    
    if (mustAuthenticated) {
        if (!isLoggedIn) {
            return <Navigate to="/" replace />;
        }
        // lakukan pengecekan dahulu ke match token jangan cuma dari isLoggedIn
        return children;
    } else {
        if (isLoggedIn) {
            // lakukan pengecekan dahulu ke match token jangan cuma dari isLoggedIn

            return <Navigate to="/account" replace />;
        }

        return children;
    }

};
export default RouterGuard;
