import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./slices/CartSlice";
import commonReducer from "./slices/CommonSlice";
import userReducer from "./slices/UserSlice";

const store = configureStore({
    reducer: { cart: cartReducer, common: commonReducer, user: userReducer },
});

store.subscribe(() => {
    console.log("STORE CHANGED: ", store.getState());
});

export default store;
