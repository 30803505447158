import { useEffect, useState, Suspense } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Tab, Tabs } from "react-bootstrap";
import NavbarSecondaryComponent from "../components/NavbarSecondaryComponent";

import { useDispatch, useSelector } from "react-redux";
import { addToCart, incCart, decCart } from "../redux/slices/CartSlice";
import { OpenFormRegisterAfterSendOTP } from "../redux/slices/CommonSlice";

import IconGoogle from "../assets/images/register/logo-google.png";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { GetVouchers, RefreshToken, Voucher } from "../Service/Api";
const NotFound = () => {
    const [tabKey, initTabKey] = useState("E-WALLET");
    const [isVerify, setIsVerify] = useState(false);
    useEffect(() => {}, [tabKey]);
    const dispatch = useDispatch();
    const carts = useSelector((state) => state.cart);
    const handleResponse = (response) => {
        console.log(response);
    };
    const [data, SetData] = useState([]);
    const reftoken = localStorage.getItem('refresh_token');
    const acctoken = localStorage.getItem('access_token');
    const refreshtoken = async() =>{
        try {
            await RefreshToken({refresh_token:reftoken}).then((response)=>{
                const data = response.data.data;
                const token = data.tokens;
                localStorage.setItem("access_token", token.access_token);
            })
        } catch (error) {
            console.log(error);
        }
    }
    const vouchers = async() =>{
        try {
            await GetVouchers({limit:10},acctoken).then((response)=>{
                console.log(response);
            })
        } catch (error) {
            console.log(error);
        }
    }
    const voucher = async() =>{
        try {
            await Voucher(1,acctoken).then((response)=>{
                console.log(response);
            })
        } catch (error) {
            console.log(error);
        }
    }
  
    const submit = (e) => {
        e.preventDefault();
        if (!isVerify) {
            return false;
        }

        alert("yow");
    };
    return (
        <div>
            <NavbarSecondaryComponent />
            <section className="section-dashboard mt-3 p-2">
                <h1>Page not found</h1>
                {/* <button type="button" onClick={refreshtoken}>Refresh token</button>
                <button type="button" onClick={vouchers}>get vouchers</button>
                <button type="button" onClick={voucher}>Voucher</button> */}
                <div className="row">
                    {/* <GoogleOAuthProvider
                        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                    > */}
                    {/* <GoogleLogin
                            render={(renderProps) => (
                                <button onClick={renderProps.onClick} className="vuse-btn btn-google bg-white bold text-uppercase border-0 relative">
                                    <img
                                        src={IconGoogle}
                                        alt="Vuse Logo Google"
                                    />{" "}
                                    Lanjutkan dengan google
                                </button>
                            )}
                            onSuccess={(credentialResponse) => {
                                console.log(credentialResponse);
                            }}
                            onError={() => {
                                console.log("Login Failed");
                            }}
                        ></GoogleLogin> */}
                    {/* <button
                                className="vuse-btn btn-google bg-white bold text-uppercase border-0 relative"
                            >
                                <img src={IconGoogle} alt="Vuse Logo Google" />{" "}
                                Lanjutkan dengan google
                            </button> */}
                    {/* </GoogleOAuthProvider> */}
                </div>
                {/* <form action="" onSubmit={submit}>
                    <div className="form-group mb-3">
                        <label htmlFor="email">Password</label>
                        <input
                            type="text"
                            className="text-dark form-control border medium"
                            placeholder="Password"
                            aria-label="Password"
                            name="password"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    <div className="form-group">
                        <ReCAPTCHA
                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            onChange={(e) => {
                                setIsVerify(true);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Daftar
                    </button>
                </form> */}
                {/* <button
                    className="btn btn-primary"
                    onClick={() => {
                        dispatch(
                            addToCart({
                                id: 1,
                                qty: 1,
                            })
                        );
                    }}
                >
                    Add cart redux 1
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        dispatch(
                            incCart({
                                id: 1,
                                qty: 1,
                            })
                        );
                    }}
                >
                    tambah 1
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        dispatch(
                            decCart({
                                id: 1,
                                qty: -1,
                            })
                        );
                    }}
                >
                    kurangi 1
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        dispatch(
                            OpenFormRegisterAfterSendOTP()
                        );
                    }}
                >
                    send OTP
                </button>

                <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                        @
                    </span>
                    <input
                        type="text"
                        className="text-dark form-control border medium"
                        placeholder="Search..."
                        aria-label="Search..."
                        aria-describedby="basic-addon1"
                    />
                </div>
            </section>
            <section className="p-2">
                <Tabs
                    activeKey={tabKey}
                    variant="pills"
                    onSelect={(e) => initTabKey(e)}
                >
                    <Tab eventKey="E-WALLET" title="E-WALLET">
                        <div className="p-2">
                            Card E-WALLET
                            <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Impedit voluptatum, neque
                                harum atque accusamus tenetur explicabo adipisci
                                incidunt debitis dolore! Sunt odio eum nobis
                                impedit accusamus dicta sint ullam quaerat?
                            </p>
                        </div>
                    </Tab>
                    <Tab eventKey="BATMERCHANDISE" title="BATMERCHANDISE">
                        <div className="p-2">
                            Card BATMERCHANDISE
                            <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Impedit voluptatum, neque
                                harum atque accusamus tenetur explicabo adipisci
                                incidunt debitis dolore! Sunt odio eum nobis
                                impedit accusamus dicta sint ullam quaerat?
                            </p>
                        </div>
                    </Tab>
                </Tabs> */}
            </section>
        </div>
    );
};

// function Loading() {
//     return (
//         <>
//             <SkeletonTheme>
//                 <div className="card mb-2">
//                     <div className="card-body">
//                         <Skeleton height={300} />
//                     </div>
//                 </div>
//             </SkeletonTheme>
//         </>
//     );
// }
export default NotFound;
