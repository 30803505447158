import React, { useState } from "react";
import { Link } from "react-router-dom";
import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";
import { CollectPoint } from "../Service/Api";

import PointBanner from "../assets/images/point/point-banner.jpg";
function PointPage() {
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) {
            return false;
        }
        setLoading(true);
        try {
            await CollectPoint({
                code: code,
            },localStorage.getItem('access_token')).then((response) => {
                setLoading(false);
                console.log(response);
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <section className="section-point sec-point-code ov-hid">
                    <div className="container-fuid no-pad">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="point-banner">
                                    <div
                                        className="point-banner-image"
                                        style={{
                                            backgroundImage:
                                                "url(" + PointBanner + ")",
                                        }}
                                    ></div>
                                    <div className="point-banner-desc">
                                        <form onSubmit={handleSubmit}>
                                            <div className="banner-desc-wrapper">
                                                <h6 className="vuse-font bold text-uppercase c-white mb8">
                                                    Masukan kode unik
                                                </h6>
                                                <input
                                                    className="form-control c-white full"
                                                    type="text"
                                                    name="code"
                                                    onChange={(e) => {
                                                        setCode(e.target.value);
                                                    }}
                                                    placeholder="MyVuseCode"
                                                />
                                            </div>
                                            <div className="cta mt24 mb24">
                                                <button
                                                    type="submit"
                                                    className="vuse-btn btn-contained text-uppercase"
                                                >
                                                    {loading
                                                        ? "Loading..."
                                                        : "Kirim"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default PointPage;
