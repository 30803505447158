import React from "react";
import { Link } from "react-router-dom";

import IconArrowLeft from "../assets/images/arrow-left.svg";

function NavBackTo(props) {
    return (
        <>
            <div className="navbar-backto d-flex full">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="backto-container">
                                <div className="backto-image">
                                    <Link
                                        to={props.backTo}
                                        className="inline-block">
                                        <img
                                            className=""
                                            alt="Vuse Icon"
                                            src={IconArrowLeft}
                                        />
                                    </Link>
                                </div>
                                <h6 className="vuse-font bold">
                                    {props.title}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavBackTo;
