import React from "react";
import { Link } from "react-router-dom";

import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";
import NavBackTo from "../components/NavBackTo";
import ImgNotif from "../assets/images/image-notif.png";

function NotificationPage(props) {
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <NavBackTo
                    type="button"
                    title="Notification"
                    backTo="/account"
                />
                <section className="section-notif mt32">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="notif-row notif-unread rd-10 mb16">
                                    <Link to="" className="d-flex">
                                        <div className="notif-image">
                                            <img
                                                src={ImgNotif}
                                                alt="Vuse Image"
                                                className="rd-10"
                                            />
                                        </div>
                                        <div className="notif-desc">
                                            <div className="notif-desc-date">
                                                <p className="vuse-small c-grey light">
                                                    22 Jun 2023 - 03:06
                                                </p>
                                            </div>
                                            <div className="notif-desc-title mb8">
                                                <h5 className="vuse-font bold">
                                                    British American Tobacco
                                                </h5>
                                            </div>
                                            <div className="notif-desc-desc">
                                                <p className="vuse-font">
                                                    Selamat Bergabung Di British
                                                    American Tobacco! Nikmati
                                                    Keuntungannya Dengan
                                                    Menggunakan Vuse Reward.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="notif-row rd-10 mb16">
                                    <Link to="" className="d-flex">
                                        <div className="notif-image">
                                            <img
                                                src={ImgNotif}
                                                alt="Vuse Image"
                                                className="rd-10"
                                            />
                                        </div>
                                        <div className="notif-desc">
                                            <div className="notif-desc-date">
                                                <p className="vuse-small c-grey light">
                                                    22 Jun 2023 - 03:06
                                                </p>
                                            </div>
                                            <div className="notif-desc-title mb8">
                                                <h5 className="vuse-font bold">
                                                    British American Tobacco
                                                </h5>
                                            </div>
                                            <div className="notif-desc-desc">
                                                <p className="vuse-font">
                                                    Selamat Bergabung Di British
                                                    American Tobacco! Nikmati
                                                    Keuntungannya Dengan
                                                    Menggunakan Vuse Reward.
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default NotificationPage;
