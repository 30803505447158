import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
    name: "common",
    initialState: {
        open_form_register_after_send_otp: localStorage.getItem('open_form_register_after_send_otp') || false,
        isLoggedIn: localStorage.getItem("logged_in") ?? false,
        input_phone_number:localStorage.getItem('input_phone_number') || '',
    },
    reducers: {
        OpenFormRegisterAfterSendOTP: (state, action) => {
            state.open_form_register_after_send_otp = action.payload;
        },
        InputPhoneNumber: (state, action) => {
            state.input_phone_number = action.payload;
        },
        IsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
    },
});



export const { IsLoggedIn, OpenFormRegisterAfterSendOTP,InputPhoneNumber } = commonSlice.actions;
export default commonSlice.reducer;
