import React from "react";

function CardCatalog({ data }) {
    const { coupon, flag, id, point } = data;
    return (
        <>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-katalog mb32">
                <div className="member-katalog-card d-flex full">
                    <div className="katalog-card-image">
                        <img
                            alt="Vuse Coupon"
                            className="inline-block"
                            src={coupon}
                        />
                    </div>
                    <div className="katalog-card-spec d-flex">
                        <div className="card-spec-avail">
                            <div className="vuse-btn btn-outlined full">
                                {flag}
                            </div>
                        </div>
                        <div className="card-spec-qty">
                            <p className="vuse-small bold">
                                <span>QTY:</span> <span>1</span>
                            </p>
                        </div>
                        <div className="card-spec-point">
                            <p className="vuse-font bold text-uppercase">
                                {point} POIN
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardCatalog;
