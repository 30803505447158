import { Link } from "react-router-dom";

const NavbarSecondaryComponent = (props) => {
    return (
        <>
            <nav id="navigation">
                <div className="navbar-container full">
                    <div className="navbar-menubar">
                        <div className="menu-logo">
                            <Link to={"/"} className="logo">
                                <img
                                style={{ 
                                    width:'auto',
                                    height:'auto',
                                 }}
                                    className="navbar-logo"
                                    alt="Icon"
                                    src="https://vusereward.vuse.com/public/images/icons/ic-left-arrow.svg"
                                />
                            </Link>
                        </div>
                        <div>
                            Title
                        </div>
                        
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavbarSecondaryComponent;
