import React, { useState } from "react";
import { RegisterStepOne, RegisterStepTwo } from "../Service/Api";
import { useDispatch } from "react-redux";
import {
    OpenFormRegisterAfterSendOTP,
    InputPhoneNumber,
} from "../redux/slices/CommonSlice";

import OtpInput from "react18-input-otp";
function FormSendOTPComponent(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [otpIsDisable, setOtpIsDisable] = useState(true);
    const [errMsg, setErrMsg] = useState("");
    const handleGetOTP = async () => {
        return false;
        if (loading) {
            return false;
        }
        try {
            setLoading(true);
            setOtpIsDisable(true);
            await RegisterStepOne({
                phone_number: phone,
            }).then((response) => {
                setLoading(false);
                setPhone(response.data.data.user.phone_number);
                console.log(response.data.data.user.otp);
                setOtpIsDisable(false);
            });
        } catch (error) {
            const errors = error.response.data.error;
            setOtpIsDisable(false);
            setLoading(false);
            alert(errors);
            // setErrMsg(errors);
        }
    };

    const handleInputOTP = async (e) => {
        setOtp(e);
        return false;
        if (e.length == 6) {
            // lakukan submit
            setOtpIsDisable(true);
            try {
                await RegisterStepTwo({
                    phone_number: phone,
                    otp: e,
                }).then((response) => {
                    localStorage.setItem(
                        "open_form_register_after_send_otp",
                        true
                    );
                    localStorage.setItem("input_phone_number", phone);
                    dispatch(OpenFormRegisterAfterSendOTP(true));

                    dispatch(InputPhoneNumber(phone));
                    setOtpIsDisable(false);
                });
            } catch (error) {
                const errors = error.response.data.error;
                setOtpIsDisable(false);
                console.log(errors);
                setErrMsg(errors);
                setOtp("");
            }
        }
    };
    return (
        <>
            <form action="">
                <div className="register-otp d-flex">
                    <div className="otp-country d-flex align-items-center bg-grey rd-10">
                        <h5 className="vuse-font bold">+62</h5>
                    </div>
                    <div className="otp-number relative">
                        <input
                            type="text"
                            className="text-dark form-control border bold bg-grey rd-10 full"
                            placeholder="Phone"
                            aria-label="Phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                            }}
                            aria-describedby="basic-addon1"
                        />
                        <div className="otp-get-code">
                            <button
                                type="button"
                                className="vuse-btn btn-otp"
                                onClick={handleGetOTP}>
                                {loading ? "Loading..." : "DAPATKAN KODE"}
                            </button>
                        </div>
                    </div>

                    <div className="otp-verif">
                        <div className="otp-verif-wrapper">
                            <OtpInput
                                value={otp}
                                // isDisabled={otpIsDisable}
                                onChange={handleInputOTP}
                                numInputs={6}
                                isInputNum={true}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default FormSendOTPComponent;
