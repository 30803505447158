import React, { useRef, useState } from "react";

import IconCopy from "../assets/images/referral/icon-copy.svg";

export default function CopyExample() {
    const [copySuccess, setCopySuccess] = useState("");
    const textCopy = useRef(null);

    function copyToClipboard(e) {
        textCopy.current.select();
        document.execCommand("copy");
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess("Berhasil di Copy!");
    }

    return (
        <div className="copy-area">
            <input
                className="text-copy full text-center"
                ref={textCopy}
                value="https://memberuserdata.com/frist3jw88w" readOnly={true} 
            ></input>
            <div className="cta-copy text-center">
                <button
                    className="vuse-btn btn-tertiary c-grey regular"
                    onClick={copyToClipboard}
                >
                    <img alt="Icon Vuse" src={IconCopy} />
                    <span className="mt8">Klik untuk menyalin</span>
                </button>
                <p className="vuse-small">{copySuccess}</p>
            </div>
        </div>
    );
}
