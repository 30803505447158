import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IsLoggedIn } from "../redux/slices/CommonSlice";
import LogoVuse from "../assets/images/logo-vuse.avif";
import IconNotif from "../assets/images/icon-notif.svg";
import IconSignin from "../assets/images/icon-signin.svg";

const NavbarPrimaryComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(IsLoggedIn(false));
        localStorage.removeItem("logged_in");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("_user");
        localStorage.removeItem("input_phone_number");
        navigate("/");
    };
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isNotifOpen, setIsNotifOpen] = useState(false);

    const isLoggedIn = useSelector((state) => state.common.isLoggedIn);
    return (
        <>
            <nav id="navigation">
                <div className="navbar-container relative full">
                    <div className="navbar-top">
                        <div className="wrapper">
                            <div className="menu-logo">
                                <Link to={"/"} className="logo">
                                    <img
                                        className="navbar-logo"
                                        alt="Icon Vuse"
                                        src={LogoVuse}
                                    />
                                </Link>
                            </div>
                            <div className="menu-right">
                                <button
                                    className="vuse-btn btn-hamburger"
                                    onClick={() => {
                                        setIsNavOpen(!isNavOpen);
                                    }}>
                                    <span></span>
                                    <span></span>
                                </button>
                                <Link
                                    to="/notification"
                                    className="text-center relative">
                                    <img alt="Vuse Icon" src={IconNotif} />
                                    <p
                                        className="vuse-small c-white"
                                        style={{
                                            lineHeight: "16px",
                                        }}>
                                        Notification
                                    </p>
                                    <div className="notif-counter">
                                        <span className="c-white bold">1</span>
                                    </div>
                                </Link>
                                {/* {!isLoggedIn ? ( */}
                                    <Link to="/" className="text-center">
                                        <img alt="Vuse Icon" src={IconSignin} />
                                        <p
                                            className="vuse-small c-white"
                                            style={{
                                                lineHeight: "16px",
                                            }}>
                                            Sign-in
                                        </p>
                                    </Link>
                                {/* ) : (
                                    <a
                                        href="#"
                                        onClick={logout}
                                        className="text-center">
                                        <img alt="Vuse Icon" src={IconSignin} />
                                        <p
                                            className="vuse-small c-white"
                                            style={{
                                                lineHeight: "16px",
                                            }}>
                                            Logout
                                        </p>
                                    </a>
                                )} */}
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            isNavOpen
                                ? "navbar-bottom nav-show"
                                : "navbar-bottom"
                        }>
                        <div className="navbar-menubar">
                            <ul className="">
                                {/* <li className="">
                                    <button
                                        type="button"
                                        className="vuse-btn btn-contained"
                                        onClick={logout}
                                    >
                                        Logout
                                    </button>
                                </li> */}
                                <li className="">
                                    <Link to={"/"}>Tentang Vuse</Link>
                                </li>
                                <li className="">
                                    <Link to={"/"}>Produk</Link>
                                </li>
                                <li className="">
                                    <Link to={"/"}>What's Hot</Link>
                                </li>
                                <li className="">
                                    <Link to={"/"}>Vuse Reward</Link>
                                </li>
                                <li className="">
                                    <Link to={"/"}>Lokasi toko</Link>
                                </li>
                                <li className="">
                                    <Link to={"/"}>Blog</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavbarPrimaryComponent;
