import React from "react";
import FooterComponent from "./FooterComponent";

function BodyContainer({ children }) {
    return (
        <>
            {children}
            <FooterComponent />
        </>
    );
}

export default BodyContainer;
