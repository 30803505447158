import React from "react";
import { Link } from "react-router-dom";

import FooterImage from "../assets/images/logo-kata.png";
import IconIG from "../assets/images/app/icon-ig.png";
import IconYoutube from "../assets/images/app/icon-youtube.png";
import IconIGBlack from "../assets/images/app/icon-ig-black.png";
import IconYoutubeBlack from "../assets/images/app/icon-youtube-black.png";

function FooterComponent() {
    return (
        <>
            <div className="footer-top-connected d-flex">
                <div className="connected-word text-center">
                    <h3 className="vuse-font text-uppercase x-bold">
                        Get connected
                    </h3>
                </div>
                <div className="connected-socmed text-center d-flex">
                    <h6 className="vuse-font text-uppercase">Follow us on</h6>
                    <a href="" target="_blank">
                        <img alt="Logo Kata.ai" src={IconIGBlack} />
                    </a>
                    <a href="" target="_blank">
                        <img alt="Logo Kata.ai" src={IconYoutubeBlack} />
                    </a>
                </div>
            </div>
            <section className="section-footer bg-dark">
                <div className="container py-4 py-md-5 px-4 px-md-3 text-body-secondary">
                    <div className="row">
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                            <h5 className="vuse-font bold text-white text-uppercase">
                                LAYANAN PELANGGAN
                            </h5>
                            <ul className="list-footer">
                                <li className="mb-2">
                                    <a href="/" className="text-secondary">
                                        Hubungi Kami
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a href="/" className="text-secondary">
                                        FAQ
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a href="/" className="text-secondary">
                                        Garansi
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a href="/" className="text-secondary">
                                        VUSE Reward
                                    </a>
                                </li>
                                <li className="mt32">
                                    <div className="footer-socmed-li d-flex">
                                        <a href="/" className="text-secondary">
                                            <img
                                                alt="Logo Kata.ai"
                                                src={IconIG}
                                            />
                                        </a>
                                        <a href="/" className="text-secondary">
                                            <img
                                                alt="Logo Kata.ai"
                                                src={IconYoutube}
                                            />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                            <h5 className="vuse-font bold text-white text-uppercase">
                                Tentang Vuse
                            </h5>
                            <ul className="list-footer">
                                <li className="mb-2">
                                    <a className="text-secondary" href="/">
                                        Tentang VUSE
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="text-secondary" href="">
                                        Program Daur Ulang
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="text-secondary" href="">
                                        Syarat dan Ketentuan
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="text-secondary" href="">
                                        Kebijakan Privasi
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="text-secondary" href="">
                                        Notifikasi Cookies
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="text-secondary" href="">
                                        Aksesibilitas
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="text-secondary" href="">
                                        Keberlanjutan
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                            <h5 className="text-white">BANTUAN LAINNYA</h5>
                            <ul className="list-footer">
                                <li className="mb-2">
                                    <a
                                        className="d-flex align-items-center"
                                        href="/">
                                        <div className="footer-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M11.1368 2.78226C11.5585 2.20248 12.3984 2.13658 12.9053 2.6435L14.0203 3.75849C14.0708 3.80896 14.1098 3.86965 14.1349 3.93648C14.8584 5.86571 14.8483 9.63755 12.0203 12.4656C9.19223 15.2936 5.4204 15.3037 3.49117 14.5802C3.42434 14.5551 3.36365 14.5161 3.31318 14.4656L2.19819 13.3506C1.69126 12.8437 1.75717 12.0038 2.33694 11.5821L4.69691 9.86579C5.30255 9.42532 6.16044 9.66141 6.45544 10.3497L7.03887 11.7111C7.25436 11.7523 7.6203 11.7433 8.10093 11.5931C8.69149 11.4086 9.37108 11.0339 9.97985 10.4252C10.5886 9.81639 10.9633 9.1368 11.1478 8.54624C11.298 8.06561 11.307 7.69967 11.2658 7.48418L9.90443 6.90075C9.2161 6.60576 8.98001 5.74786 9.42048 5.14222L11.1368 2.78226ZM12.1982 3.35061C12.1258 3.27819 12.0058 3.2876 11.9455 3.37043L10.2292 5.73039C10.1663 5.81691 10.2 5.93947 10.2983 5.98161L11.8637 6.65247C11.9724 6.69906 12.0611 6.78266 12.1139 6.88844C12.3665 7.39349 12.3253 8.13106 12.1023 8.84451C11.8702 9.58729 11.4115 10.4077 10.687 11.1323C9.96239 11.8568 9.14197 12.3155 8.3992 12.5476C7.68574 12.7706 6.94818 12.8118 6.44312 12.5593C6.33735 12.5064 6.25374 12.4177 6.20716 12.309L5.5363 10.7437C5.49415 10.6453 5.3716 10.6116 5.28508 10.6745L2.92512 12.3909C2.84229 12.4511 2.83288 12.5711 2.90529 12.6435L3.94133 13.6795C5.6011 14.2532 8.85772 14.214 11.3132 11.7585C13.7686 9.30303 13.8078 6.04642 13.2342 4.38664L12.1982 3.35061Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                        Layanan Hotline Berbayar 150-300
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a
                                        className="d-flex align-items-center"
                                        href="/">
                                        <div className="footer-icon">
                                            <svg
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g
                                                    id="whatsapp 1"
                                                    clipPath="url(#clip0_80_3318)">
                                                    <g id="Group">
                                                        <g id="Group_2">
                                                            <path
                                                                id="Vector"
                                                                d="M12.0044 10.0777L11.1565 9.22979C10.9251 8.99844 10.6054 8.88251 10.2798 8.91179C9.95401 8.94101 9.66023 9.11191 9.47376 9.38063L9.17839 9.80629C8.7767 9.73472 7.99961 9.53219 7.45642 8.98897C6.91301 8.44554 6.71051 7.6681 6.63907 7.26701L7.06482 6.9716C7.33357 6.7851 7.50448 6.49126 7.53364 6.16544C7.56286 5.83963 7.44692 5.52013 7.21564 5.28888L6.3677 4.44094C6.02695 4.10022 5.51336 4.01891 5.08961 4.23847C4.34661 4.62341 3.85854 5.34497 3.71517 6.27022C3.48164 7.77769 4.17854 9.55613 5.53389 10.9115C6.71595 12.0935 8.21973 12.7747 9.58495 12.7747C9.78514 12.7747 9.98239 12.7601 10.1751 12.7302C11.1004 12.5869 11.822 12.0988 12.2069 11.3558C12.4264 10.932 12.3451 10.4184 12.0044 10.0777ZM11.3441 10.9088C11.0382 11.4993 10.4587 11.703 10.0264 11.77C8.83779 11.954 7.34404 11.3474 6.22095 10.2244C5.09792 9.10132 4.49123 7.60763 4.67536 6.41897C4.74229 5.98666 4.94611 5.40716 5.53654 5.10122C5.55254 5.09297 5.57023 5.08894 5.58817 5.08894C5.62117 5.08894 5.65504 5.10254 5.68054 5.12801L6.52851 5.97597C6.56373 6.01126 6.56823 6.05132 6.56576 6.07872C6.56329 6.10616 6.55179 6.14479 6.51079 6.17322L5.84101 6.63797C5.70095 6.73516 5.62204 6.89872 5.6332 7.06885C5.63742 7.1331 5.74792 8.6546 6.76929 9.67597C7.79067 10.6974 9.31217 10.8079 9.37642 10.8121C9.54642 10.823 9.71014 10.7443 9.80732 10.6043L10.2721 9.93447C10.3005 9.89354 10.3391 9.882 10.3665 9.87954C10.394 9.877 10.434 9.88157 10.4694 9.91682L11.3173 10.7647C11.3567 10.8041 11.3677 10.8633 11.3441 10.9088Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g id="Group_3">
                                                        <g id="Group_4">
                                                            <path
                                                                id="Vector_2"
                                                                d="M13.6567 2.78856C12.1457 1.27747 10.1367 0.445312 7.99967 0.445312C5.86267 0.445312 3.85367 1.27747 2.34261 2.78853C1.05833 4.07281 0.243017 5.77253 0.0467671 7.57459C-0.138764 9.27819 0.231486 11.0122 1.09049 12.4821L0.256798 15.5934C0.211861 15.7611 0.259798 15.94 0.38258 16.0627C0.505298 16.1855 0.684267 16.2334 0.85183 16.1885L3.96314 15.3548C5.18514 16.069 6.5898 16.4453 8.00764 16.4453C8.29524 16.4453 8.58339 16.4298 8.87067 16.3985C10.6727 16.2023 12.3725 15.387 13.6567 14.1027C15.1678 12.5916 16 10.5826 16 8.44563C16 6.30869 15.1678 4.29963 13.6567 2.78856ZM12.9696 13.4156C10.6468 15.7384 7.07902 16.1512 4.29349 14.4194C4.21574 14.3712 4.12677 14.3462 4.03692 14.3462C3.99486 14.3462 3.95261 14.3517 3.9112 14.3628L1.41317 15.0321L2.08255 12.5341C2.1173 12.4043 2.09677 12.2659 2.02586 12.1519C0.29408 9.36622 0.706892 5.79844 3.02967 3.47563C5.77017 0.735188 10.2292 0.735188 12.9696 3.47563C15.7101 6.21606 15.7101 10.6751 12.9696 13.4156Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_80_3318">
                                                        <rect
                                                            width="16"
                                                            height="16"
                                                            fill="white"
                                                            transform="translate(0 0.445312)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        Layanan Whatsapp
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a
                                        className="d-flex align-items-center"
                                        href="/">
                                        <div className="footer-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M3.00008 4.44531C2.53984 4.44531 2.16675 4.81841 2.16675 5.27865V5.65704L8.00008 9.03423L13.8334 5.65704V5.27865C13.8334 4.81841 13.4603 4.44531 13.0001 4.44531H3.00008ZM13.8334 6.81254L9.97748 9.04492L13.8241 12.7367C13.8303 12.696 13.8334 12.6544 13.8334 12.612V6.81254ZM13.1107 13.438L9.07668 9.56643L8.2506 10.0447C8.09564 10.1344 7.90453 10.1344 7.74956 10.0447L6.92348 9.56643L2.88944 13.438C2.92564 13.4428 2.96257 13.4453 3.00008 13.4453H13.0001C13.0376 13.4453 13.0745 13.4428 13.1107 13.438ZM2.17602 12.7367L6.02268 9.04492L2.16675 6.81254V12.612C2.16675 12.6544 2.16991 12.696 2.17602 12.7367ZM1.16675 5.27865C1.16675 4.26612 1.98756 3.44531 3.00008 3.44531H13.0001C14.0126 3.44531 14.8334 4.26612 14.8334 5.27865V12.612C14.8334 13.6245 14.0126 14.4453 13.0001 14.4453H3.00008C1.98756 14.4453 1.16675 13.6245 1.16675 12.612V5.27865Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                        Email info.id@vuse.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mt32">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <div className="footer-disclaimer">
                                <p className="vuse-font c-white">
                                    *Produk ini hanya untuk 18 tahun ke atas,
                                    mengandung nikotin dan dapat menyebabkan
                                    ketergantungan
                                </p>
                                <p className="vuse-font c-white mt16">
                                    *Berdasarkan estimasi value share Vype/Vuse
                                    dari harga eceran yang direkomendasikan pada
                                    retail yang diukur untuk produk vapour
                                    (yaitu total nilai penjualan pada kategori
                                    produk vapour di retail) di pasar utama
                                    produk vapour: Amerika Serikat, Kanada,
                                    Perancis, United Kingdom, Jerman pada Mei
                                    2022.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="footer-copyright bg-black c-white">
                {/* <a
                    href="https://landing.kata.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <p className="kata-font c-small x-bold">
                        Powered by
                        <img alt="Logo Kata.ai" src={FooterImage} />
                    </p>
                </a> */}
                <p className="vuse-font c-grey">
                    <i>© 2023 VUSE</i>. All rights reserved.
                </p>
            </div>
        </>
    );
}

export default FooterComponent;
