import React, { useState } from "react";
import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";

import WarrantyBanner from "../assets/images/referral/warranty-banner.jpg";
import UploadImg from "../assets/images/referral/upload-image.jpg";
import { Warranty } from "../Service/Api";

function WarrantyPage() {
    const [image, setImage] = useState(
        "https://content.hostgator.com/img/weebly_image_sample.png"
    );

    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState("No file selected");
    const [inputFile, setInputFile] = useState("");
    const [inputDevice, setInputDevice] = useState("");
    const [errMsgDevice, setErrMsgDevice] = useState("");
    const [errMsgFile, setErrMsgFile] = useState("");
    const handlePreviewImage = (e) => {
        e.target.files[0] && setFileName(e.target.files[0].name);
        setInputFile("");
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            alert("select valid image.");
            setInputFile("");
            return false;
        }
        if (e.target.files) {
            setInputFile(e.target.files[0]);
            setImage(URL.createObjectURL(e.target.files[0]));
            URL.revokeObjectURL(e.target.files[0]);
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (loading) {
            return false;
        }
        setErrMsgDevice("");
        setErrMsgFile("");
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append("device_code", inputDevice);
            formData.append("receipt_image", inputFile);
            await Warranty(formData, localStorage.getItem("access_token")).then(
                () => {
                    setLoading(false);
                }
            );
        } catch (error) {
            const errors = error.response.data.error;
            for (var i in errors) {
                if (i == "device_code") {
                    setErrMsgDevice(errors[i]);
                }
                if (i == "receipt_image") {
                    setErrMsgFile(errors[i]);
                }
            }
            setLoading(false);
        }
    };
    const validateInput = () => {};
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <section className="section-referral ov-hid mb64">
                    <div className="container-fuid no-pad">
                        <div
                            className="ref-banner"
                            style={{
                                backgroundImage: "url(" + WarrantyBanner + ")",
                            }}></div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <form onSubmit={handleSubmit}>
                                    <div className="ref-container">
                                        <div className="ref-word mb32">
                                            <h4 className="vuse-font bold text-uppercase mb8">
                                                perpanjang masa garansi epod
                                            </h4>
                                            <p className="vuse-font">
                                                Upgrade masa garansi device ePod
                                                mu manjadi 1 tahun dengan upload
                                                bukti pembelian
                                            </p>
                                        </div>
                                        <div className="ref-word mb24">
                                            <h4 className="vuse-font bold text-uppercase mb8">
                                                CARANYA:
                                            </h4>
                                            <ul className="p-0 m-0">
                                                <li className="vuse-font mb4">
                                                    <span className="bold">
                                                        Step 1
                                                    </span>
                                                    : Masukkan kode device yang
                                                    anda miliki
                                                </li>
                                                <li className="vuse-font mb4">
                                                    <span className="bold">
                                                        Step 2
                                                    </span>
                                                    : Upload struk pembelian
                                                    Step
                                                </li>
                                                <li className="vuse-font mb4">
                                                    <span className="bold">
                                                        Step 3
                                                    </span>
                                                    : Garansi device kamu akan
                                                    menjadi 1 tahun setelah masa
                                                    pembelian (Validasi 1x24
                                                    Jam)
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="ref-input mb48">
                                            <label className="control-label">
                                                Masukkan Kode Device Anda
                                            </label>
                                            <input
                                                type="text"
                                                className="input-form full"
                                                name="device_code"
                                                onChange={(e) => {
                                                    setInputDevice(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="Silahkan masukkan kode device yang Anda miliki*"
                                            />
                                            <span>{errMsgDevice}</span>
                                        </div>
                                        <div className="ref-upload">
                                            <label className="control-label">
                                                Upload struk pembelian *wajib*
                                            </label>
                                            <p className="vuse-font">
                                                <label
                                                    className="label-image"
                                                    htmlFor="image"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}>
                                                    <input
                                                        id="image"
                                                        type="file"
                                                        hidden="true"
                                                        className="form-control"
                                                        onChange={
                                                            handlePreviewImage
                                                        }
                                                        accept="image/png,image/jpg,image/jpeg"
                                                    />
                                                    <img
                                                        src={image}
                                                        alt={fileName}
                                                    />
                                                </label>
                                            </p>
                                            <span>{errMsgFile}</span>
                                        </div>
                                        <div className="cta mt32">
                                            <button className="vuse-btn btn-contained text-uppercase full">
                                                {loading
                                                    ? "Loading...."
                                                    : "Kirim"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default WarrantyPage;
