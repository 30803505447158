import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Login } from "../Service/Api";
import { IsLoggedIn } from "../redux/slices/CommonSlice";
import { DataUser } from "../redux/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    useGoogleLogin,
    GoogleLogin,
    GoogleOAuthProvider,
} from "@react-oauth/google";
import BodyContainer from "../components/BodyContainer";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";

import PointBanner from "../assets/images/point/point-banner.jpg";
import IconBenefit1 from "../assets/images/register/benefit-1.svg";
import IconBenefit2 from "../assets/images/register/benefit-2.svg";
import IconBenefit3 from "../assets/images/register/benefit-3.svg";
import IconBenefit4 from "../assets/images/register/benefit-4.svg";
import ArrowRight from "../assets/images/register/arrow-right.svg";
import IconGoogle from "../assets/images/register/logo-google.png";

function RegistrationPage(props) {
    const [loading, setLoading] = useState(false);
    const [errPhoneNumber, setErrPhoneNumber] = useState("");
    const [errPassword, setErrPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [parseData, setParseData] = useState({
        phone_number: "",
        password: "",
    });
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setParseData({ ...parseData, [name]: value });
    };

    const userAgent = window.navigator.userAgent;
    const handleSubmit = async (event) => {
        event.preventDefault();
        return false;
        setErrPhoneNumber("");
        setErrPassword("");
        if (loading) {
            return false;
        }
        try {
            setLoading(true);
            await Login(parseData).then((response) => {
                const data = response.data.data;
                const user = data.user;
                const token = data.access_token;
                const decoded = jwt_decode(token);
                localStorage.setItem("_user", JSON.stringify(user));
                localStorage.setItem("access_token", token);
                localStorage.setItem("_exp", decoded.exp);
                dispatch(DataUser(JSON.stringify(user)));
                localStorage.setItem("logged_in", true);
                dispatch(IsLoggedIn(true));
                setLoading(false);
                // localStorage.setItem("refresh_token", token.refresh_token);
                // const decoded2 = jwt_decode(data.tokens.refresh_token);
            });
            navigate("/account");
        } catch (error) {
            setLoading(false);
            const errors = error.response.data.error;
            if (typeof errors == "string") {
                alert(errors);
            } else {
                for (var i in errors) {
                    if (i == "phone_number") {
                        setErrPhoneNumber(errors[i]);
                    }
                    if (i == "password") {
                        setErrPassword(errors[i]);
                    }
                }
            }
        }
    };
    const handleLoginWithGoogle = async (credential) => {
        return;
    };
   
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <section className="section-point">
                    <div className="container-fluid no-pad">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="point-banner">
                                    <div
                                        className="point-banner-image"
                                        style={{
                                            backgroundImage:
                                                "url(" + PointBanner + ")",
                                        }}
                                    ></div>
                                    <div className="point-banner-desc">
                                        <div className="banner-desc-wrapper">
                                            <h1 className="vuse-font text-uppercase c-white">
                                                DAFTAR DAN DAPATKAN REWARDNYA
                                            </h1>
                                        </div>
                                        <div className="cta mt24 mb24">
                                            <Link
                                                to="/registration-form"
                                                className="vuse-btn btn-white text-uppercase"
                                            >
                                                Daftar sekarang
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-register-benefit mt32 mb32">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                <div className="register-word">
                                    <h2 className="vuse-font x-bold text-uppercase mb16">
                                        Keuntungan menjadi anggota vuse rewards
                                    </h2>
                                    <p className="vuse-font text-capitalize">
                                        Daftar sekarang dan nikmati banyak
                                        keuntungan dengan menjadi Anggota VUSE
                                        Reward.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                <div className="register-benefit d-flex">
                                    <div className="benefit-wrapper">
                                        <img
                                            src={IconBenefit1}
                                            alt="Vuse Logo"
                                        />
                                        <p className="vuse-small text-uppercase bold">
                                            Reward bulanan
                                        </p>
                                    </div>
                                    <div className="benefit-wrapper">
                                        <img
                                            src={IconBenefit2}
                                            alt="Vuse Logo"
                                        />
                                        <p className="vuse-small text-uppercase bold">
                                            Undangan ke event music vuse
                                        </p>
                                    </div>
                                    <div className="benefit-wrapper">
                                        <img
                                            src={IconBenefit3}
                                            alt="Vuse Logo"
                                        />
                                        <p className="vuse-small text-uppercase bold">
                                            ekstra points dari pembelanjaan
                                        </p>
                                    </div>
                                    <div className="benefit-wrapper">
                                        <img
                                            src={IconBenefit4}
                                            alt="Vuse Logo"
                                        />
                                        <p className="vuse-small text-uppercase bold">
                                            E-wallet dengan ajak teman dari
                                            referral
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-register-form mt32">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-5 offset-lg-4">
                                <h3 className="vuse-font bold text-uppercase text-center mb40">
                                    Masuk untuk melihat akun kamu
                                </h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label bold"
                                        >
                                            No Telepon / Whatsapp
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="phone_number"
                                            onChange={handleChangeInput}
                                        />
                                        {errPhoneNumber ?? ""}
                                    </div>
                                    <div className="form-group">
                                        <label
                                            htmlFor=""
                                            className="control-label bold"
                                        >
                                            KATA SANDI
                                        </label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="password"
                                            onChange={handleChangeInput}
                                        />
                                        {errPassword ?? ""}
                                    </div>
                                    <div className="forget-pass-link">
                                        <label
                                            htmlFor=""
                                            className="control-label"
                                        >
                                            <Link
                                                to=""
                                                className="c-black bold"
                                            >
                                                LUPA KATA SANDI?{" "}
                                                <span className="">
                                                    <img
                                                        alt=""
                                                        src={ArrowRight}
                                                    ></img>
                                                </span>
                                            </Link>
                                        </label>
                                    </div>
                                    <div className="cta mt32">
                                        <button
                                            type="submit"
                                            className="vuse-btn btn-contained text-uppercase full"
                                        >
                                            {loading ? "Loading ..." : "Kirim"}
                                        </button>
                                    </div>
                                </form>
                                <div className="register-divider"></div>
                                <div className="register-acc">
                                    <h5 className="vuse-font text-uppercase text-center mb24">
                                        Sudah memiliki akun?
                                    </h5>
                                    <div className="register-wrapper">
                                        <Link
                                            to="/registration-form"
                                            className="vuse-btn btn-outline text-uppercase"
                                        >
                                            Daftar
                                        </Link>
                                    </div>
                                    <p className="vuse-font bold">ATAU</p>
                                    <div className="register-wrapper">
                                        <button
                                            className="vuse-btn btn-google bg-white bold text-uppercase border-0 relative"
                                            onClick={handleLoginWithGoogle}
                                        >
                                            <img
                                                src={IconGoogle}
                                                alt="Vuse Logo Google"
                                            />{" "}
                                            Lanjutkan dengan google
                                        </button>
                                        {/* <GoogleOAuthProvider
                                            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                                        >
                                            <GoogleLogin
                                                onSuccess={(
                                                    credentialResponse
                                                ) => {
                                                    console.log(
                                                        jwt_decode(
                                                            credentialResponse.credential
                                                        )
                                                    );
                                                }}
                                                onError={() => {
                                                    console.log("Login Failed");
                                                }}
                                            ></GoogleLogin>
                                        </GoogleOAuthProvider> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default RegistrationPage;
