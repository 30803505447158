import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import HeroBanner from "../assets/images/account/hero-banner.png";
import LevelGold from "../assets/images/account/level-gold.png";
import LevelDiamond from "../assets/images/account/level-diamond.png";
import LevelPlatinum from "../assets/images/account/level-platinum.png";
import BannerWarranty from "../assets/images/account/banner-waranty.jpg";
import BannerPoint from "../assets/images/account/banner-point.jpg";
import BannerReferral from "../assets/images/account/banner-referral.jpg";
import BannerContact from "../assets/images/account/banner-contact.png";
import BannerClaim from "../assets/images/account/banner-claim.jpg";
import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";
import BodyContainer from "../components/BodyContainer";
import ProgressBarComponent from "../components/ProgressBarComponent";

const testData = [
    { bgcolor: "#f7be2c", completed: 5 },
    // { bgcolor: "#313f6b", completed: 30 }, Color Diamond
    // { bgcolor: "#5b5b5b", completed: 53 }, Color Platinum
];


function AccountPage(props) {
    // const selector = useSelector((state) => state.user);
    // const user = JSON.parse(selector.user);
    // console.log(user);
    const user = {
        name : "Jhon",
        point: 10,
    }
    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <section className="section-account-banner ov-hid">
                    <div className="container-fuid no-pad">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="account-banner">
                                    <div
                                        className="account-banner-image"
                                        style={{
                                            backgroundImage:
                                                "url(" + HeroBanner + ")",
                                        }}></div>
                                    <div className="account-banner-desc">
                                        <h1 className="vuse-font x-bold text-uppercase c-white">
                                            {user.name}
                                        </h1>
                                        <div className="banner-level mt8">
                                            <div className="banner-level-img">
                                                <img
                                                    alt="Vuse Level"
                                                    src={LevelGold}
                                                />
                                            </div>
                                            <p className="vuse-font text-uppercase c-white regular mt8">
                                                {user.point} POINTS
                                            </p>
                                        </div>
                                        <div className="cta mt24 mb24">
                                            <Link
                                                to="/point"
                                                className="vuse-btn btn-white text-uppercase">
                                                Kumpulkan poin dengan whatsapp
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-account-level relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="cta">
                                    <Link
                                        to="/member/gold"
                                        className="vuse-btn btn-tertiary">
                                        Cari Tahu
                                    </Link>
                                </div>
                                <div className="account-level-container">
                                    <div className="account-level-word">
                                        <h1 className="vuse-font text-uppercase">
                                            Level kamu
                                        </h1>
                                    </div>
                                    <div className="account-level-tier">
                                        <div className="level-tier-member">
                                            <div className="banner-level-img text-center">
                                                <img
                                                    alt="Vuse Level"
                                                    src={LevelGold}
                                                />
                                                <p className="vuse-font bold">
                                                    Gold
                                                </p>
                                            </div>
                                            <div className="banner-level-img text-center">
                                                <img
                                                    alt="Vuse Level"
                                                    src={LevelDiamond}
                                                />
                                                <p className="vuse-font bold">
                                                    Diamond
                                                </p>
                                            </div>
                                            <div className="banner-level-img text-center">
                                                <img
                                                    alt="Vuse Level"
                                                    src={LevelPlatinum}
                                                />
                                                <p className="vuse-font bold">
                                                    Platinum
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {testData.map((item, idx) => (
                                        <ProgressBarComponent
                                            key={idx}
                                            bgcolor={item.bgcolor}
                                            completed={item.completed}
                                        />
                                    ))}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-account-banner_mini relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                <div
                                    className="banner_mini-card"
                                    style={{
                                        backgroundImage:
                                            "url(" + BannerWarranty + ")",
                                    }}>
                                    <Link to="" className="block full">
                                        <div className="banner_mini-card-desc">
                                            <h3 className="vuse-font xx-bold text-uppercase">
                                                Garansi Pelanggan
                                            </h3>
                                            <div className="cta mt16">
                                                <div className="vuse-btn btn-outlined text-uppercase">
                                                    cara Klaim garansi
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                <div
                                    className="banner_mini-card"
                                    style={{
                                        backgroundImage:
                                            "url(" + BannerPoint + ")",
                                    }}>
                                    <Link to="" className="block full">
                                        <div className="banner_mini-card-desc">
                                            <h3 className="vuse-font xx-bold text-uppercase">
                                                Kumpulkan poin reward
                                            </h3>
                                            <div className="cta mt16">
                                                <div className="vuse-btn btn-outlined text-uppercase">
                                                    Kumpulkan sekarang
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                <div
                                    className="banner_mini-card"
                                    style={{
                                        backgroundImage:
                                            "url(" + BannerReferral + ")",
                                    }}>
                                    <Link to="" className="block full">
                                        <div className="banner_mini-card-desc">
                                            <h3 className="vuse-font xx-bold text-uppercase">
                                                Dapatkan <br></br> E-Wallet
                                                Dengan ajak teman via referral
                                            </h3>
                                            <div className="cta mt16">
                                                <div className="vuse-btn btn-outlined text-uppercase">
                                                    Ajak teman
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-account-contact">
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-12 col-sm-8 order-sm-2 col-md-7 offset-md-1 order-md-2 col-lg-7 order-lg-2">
                                <div className="account-contact-image">
                                    <img alt="Vuse Level" src={BannerContact} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 order-sm-1 col-md-4 order-m-1 col-lg-4 order-lg-1">
                                <div className="account-contact-desc">
                                    <h3 className="vuse-font bold text-uppercase">
                                        Hubungi kami di whatsapp untuk:
                                    </h3>
                                    <ol className="mt16">
                                        <li classname="">
                                            <p className="vuse-font c-grey text-capitalize">
                                                Kumpulkan Poin
                                            </p>
                                        </li>
                                        <li classname="">
                                            <p className="vuse-font c-grey text-capitalize">
                                                Cek Poin Reward
                                            </p>
                                        </li>
                                        <li classname="">
                                            <p className="vuse-font c-grey text-capitalize">
                                                Cek Lokasi Toko Terdekat
                                            </p>
                                        </li>
                                        <li className="">
                                            <p className="vuse-font c-grey text-capitalize">
                                                FAQ
                                            </p>
                                        </li>
                                        <li classname="">
                                            <p className="vuse-font c-grey text-capitalize">
                                                chat live agent
                                            </p>
                                        </li>
                                    </ol>
                                    <div className="cta">
                                        <Link
                                            to=""
                                            className="vuse-btn btn-contained text-uppercase">
                                            Chat sekarang
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-account-claim ov-hid">
                    <div className="container-fluid no-pad">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="account-claim-container">
                                    <div className="account-claim-desc">
                                        <h4 className="vuse-font x-bold text-uppercase c-white">
                                            Cek Reward yang kamu punya
                                        </h4>
                                        <p className="vuse-font c-white text-capitalize mt8">
                                            Discount voucher, Special Invitation
                                            to Events, F1 McLaren Merchandise
                                            Menantimu
                                        </p>
                                        <div className="cta mt24 mb24">
                                            <Link
                                                  to="/member/gold"
                                                className="vuse-btn btn-outlined outlined-white text-uppercase">
                                                Claim Rewardmu
                                            </Link>
                                        </div>
                                    </div>
                                    <div
                                        className="account-claim-image"
                                        style={{
                                            backgroundImage:
                                                "url(" + BannerClaim + ")",
                                        }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

export default AccountPage;
