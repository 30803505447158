import React, { useEffect, useState, Suspense } from "react";
import { useParams } from "react-router";
import { GetTier } from "../Service/Api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import LevelGold from "../assets/images/account/level-gold.png";
import LevelDiamond from "../assets/images/account/level-diamond.png";
import LevelPlatinum from "../assets/images/account/level-platinum.png";
import BannerWarranty from "../assets/images/account/banner-waranty.jpg";
import CardGold from "../assets/images/member/card-gold.png";
import CardDiamond from "../assets/images/member/card-diamond.png";
import CardPlatinum from "../assets/images/member/card-platinum.png";
import CouponReward from "../assets/images/member/coupon-reward.png";
import CouponGopay from "../assets/images/member/coupon-gopay.png";
import CouponConcert from "../assets/images/member/coupon-concert.jpg";

import NavbarPrimaryComponent from "../components/NavbarPrimaryComponent";
import BodyContainer from "../components/BodyContainer";

import CardCatalog from "../components/Member/CardCatalog";
import NavBackTo from "../components/NavBackTo";
import HeaderCatalog from "../components/Member/HeaderCatalog";
// const HeaderCatalog = React.lazy(() =>
//     import("../components/Member/HeaderCatalog")
// );
function MemberPage(props) {
    const { level } = useParams();
    const members = [
        {
            gold: [
                {
                    header: {
                        tier_background:
                            "linear-gradient(#FFEEB9, #FFC000)",
                        tier_name: "Gold",
                        tier_icon: LevelGold,
                        tier_card: CardGold,
                        point_low: 0,
                        point_high: 120,
                    },
                    details: [
                        {
                            id: 1,
                            coupon: CouponReward,
                            flag: "Tersedia",
                            point: 10,
                        },
                    ],
                },
            ],
            diamond: [
                {
                    header: {
                        tier_background:
                            "linear-gradient(#2D3B66, #081227)",
                        tier_name: "Gold",
                        tier_icon: LevelDiamond,
                        tier_card: CardDiamond,
                        point_low: 0,
                        point_high: 120,
                    },
                    details: [
                        {
                            id: 1,
                            coupon: CouponReward,
                            flag: "Tersedia",
                            point: 10,
                        },
                        {
                            id: 1,
                            coupon: CouponReward,
                            flag: "Sudah digunakan",
                            point: 10,
                        },
                    ],
                },
            ],
            platinum: [
                {
                    header: {
                        tier_background:
                            "linear-gradient(#232323, #232323)",
                        tier_name: "Platinum",
                        tier_icon: LevelPlatinum,
                        tier_card: CardPlatinum,
                        point_low: 0,
                        point_high: 520,
                    },
                    details: [
                        {
                            id: 1,
                            coupon: CouponReward,
                            flag: "Tersedia",
                            point: 10,
                        },
                        {
                            id: 2,
                            coupon: CouponReward,
                            flag: "Sudah digunakan",
                            point: 20,
                        },
                        {
                            id: 3,
                            coupon: CouponConcert,
                            flag: "Kadaluwarsa",
                            point: 30,
                        },
                        {
                            id: 4,
                            coupon: CouponGopay,
                            flag: "Stok kosong",
                            point: 40,
                        },
                    ],
                },
            ],
        },
    ];
    const header = members.map((val, item) => {
        if (!val[level]) {
            return val["platinum"][0]["header"]; // sementara kalo tidak ketemu ambil default ke platinum
        }
        return val[level][0]["header"];
    });
    const details = members.map((val, item) => {
        if (!val[level]) {
            return val["platinum"][0]["details"]; // sementara kalo tidak ketemu ambil default ke platinum
        }
        return val[level][0]["details"];
    });
    // const [dataHeader, setDataHeader] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // const tier = async () => {
        //     try {
        //         await GetTier(level, localStorage.getItem("access_token")).then(
        //             (response) => {
        //                 const result = response.data.data.tier;
        //                 setDataHeader(result);
        //             }
        //         );
        //     } catch (error) {
        //         console.log(error);
        //     }
        // };
        // tier();
        // console.log(header[0]);
    }, []);

    return (
        <>
            <BodyContainer>
                <NavbarPrimaryComponent />
                <NavBackTo backTo="/" title="Level saya" />
                    <HeaderCatalog data={header[0]} />
                {/* <Suspense fallback={<Loading />}> */}
                {/* </Suspense> */}

                {/* <section
                    className="section-member-banner ov-hid"
                    style={{
                        background:
                            "linear-gradient(220deg, #3C3C3C 0%, #000102 100%), #F2C94C",
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="member-banner-container d-flex">
                                    <div className="member-banner-point d-flex">
                                        <div className="banner-point-image d-flex align-items-center">
                                            <img
                                                alt="Vuse Level"
                                                className="inline-block"
                                                src={LevelPlatinum}
                                            />
                                            <div className="inline-block">
                                                <p className="vuse-font">
                                                    Levelmu
                                                </p>
                                                <h4 className="vuse-font x-bold text-uppercase">
                                                    PLATINUM
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="banner-point-total text-end">
                                            <p className="vuse-font">
                                                Nilai Pembelian
                                            </p>
                                            <h4 className="vuse-font x-bold text-uppercase">
                                                520
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="member-banner-card">
                                        <img
                                            alt="Vuse Level"
                                            src={CardPlatinum}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="section-member-katalog">
                    <div className="container">
                        <div className="row row-member">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                                <div className="subject-tittle">
                                    <h3 className="vuse-font black bold text-uppercase">
                                        KATALOG REWARD
                                    </h3>
                                </div>
                            </div>
                            {details[0].map((val, idx) => {
                                return <CardCatalog data={val} key={idx} />;
                            })}
                            {/* <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb32">
                                <div className="member-katalog-card d-flex full">
                                    <div className="katalog-card-image">
                                        <img
                                            alt="Vuse Coupon"
                                            className="inline-block"
                                            src={CouponReward}
                                        />
                                    </div>
                                    <div className="katalog-card-spec d-flex">
                                        <div className="card-spec-avail">
                                            <div className="vuse-btn btn-outlined full">
                                                Sudah digunakan
                                            </div>
                                        </div>
                                        <div className="card-spec-qty">
                                            <p className="vuse-small bold">
                                                <span>QTY:</span> <span>1</span>
                                            </p>
                                        </div>
                                        <div className="card-spec-point">
                                            <p className="vuse-font bold text-uppercase">
                                                XXX POIN
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb32">
                                <div className="member-katalog-card d-flex full">
                                    <div className="katalog-card-image">
                                        <img
                                            alt="Vuse Coupon"
                                            className="inline-block"
                                            src={CouponConcert}
                                        />
                                    </div>
                                    <div className="katalog-card-spec d-flex">
                                        <div className="card-spec-avail">
                                            <div className="vuse-btn btn-outlined full">
                                                Kedaluwarsa
                                            </div>
                                        </div>
                                        <div className="card-spec-qty">
                                            <p className="vuse-small bold">
                                                <span>QTY:</span> <span>1</span>
                                            </p>
                                        </div>
                                        <div className="card-spec-point">
                                            <p className="vuse-font bold text-uppercase">
                                                XXX POIN
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mb32">
                                <div className="member-katalog-card d-flex full">
                                    <div className="katalog-card-image">
                                        <img
                                            alt="Vuse Coupon"
                                            className="inline-block"
                                            src={CouponGopay}
                                        />
                                    </div>
                                    <div className="katalog-card-spec d-flex">
                                        <div className="card-spec-avail">
                                            <div className="vuse-btn btn-outlined full">
                                                Stok kosong
                                            </div>
                                        </div>
                                        <div className="card-spec-qty">
                                            <p className="vuse-small bold">
                                                <span>QTY:</span> <span>0</span>
                                            </p>
                                        </div>
                                        <div className="card-spec-point">
                                            <p className="vuse-font bold text-uppercase">
                                                XXX POIN
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row mt32 mb16">
                            <div className="col-12 col-sm-3 offset-sm-5 col-md-3 offset-md-5 col-lg-3 offset-lg-5 text-center">
                                <button className="vuse-btn btn-contained text-uppercase full">
                                    Claim rewardmu
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </BodyContainer>
        </>
    );
}

function Loading() {
    return (
        <>
            <Skeleton count={1} height={300}></Skeleton>
        </>
    );
}

export default MemberPage;
